<template>
  <div style="margin: 10px;">
    <b-row>
      <b-col sm="12" lg="1"></b-col>
      <b-col sm="12" lg="8">
        <work-subject :p_showDetails="{'type': 'selected', 'subject_name': 'treatmentdrugcycledayarrangement'}"></work-subject>
        <hr>
        <h3>Komponent Kullanımı</h3>
        <div style="width: 100%;">
          <img src="../../../public/demo/treatmentdrugcycledayarrangement.png" width="100%">
        </div>
        <h3>Komponent açılan modalde görünüm</h3>
        <h6>Butona tıklayarak açılan modalde tarih değiştirme komponentini görüntüleyebilirsiniz</h6>
        <b-row>
          <b-col sm="12" lg="12">
            <b-button @click="f_openTreatmentDrugCycleDayArrange()" variant="primary">Siklus & Gün Düzenleme</b-button>
          </b-col>
        </b-row>
        <hr>
        <template v-for="(code_example, code_example_index)  in d_componentExample.schema_drugs">
          <h3> {{ code_example.name }} </h3>
          <div class="code-text">{{ code_example.code }}</div>
        </template>
        <hr>
        <sql-database :p_tableShowDetails="{'type': 'selected', 'table_name': 'PatientTreatmentDrugs'}"></sql-database>
        <hr>
        <sql-database :p_tableShowDetails="{'type': 'selected', 'table_name': 'PatientTreatmentDrugsApplication'}"></sql-database>
        <hr>
      </b-col>
      <b-col sm="12" lg="3"></b-col>
    </b-row>
    <b-modal v-if="d_treatmentDrugCycleDayArrange.show" v-model="d_treatmentDrugCycleDayArrange.show" centered class="modal-success" @ok="d_treatmentDrugCycleDayArrange.show = false" ok-variant="success" hide-footer size="lg">
      <b-row>
        <b-col cols="12">
          <img src="../../../public/demo/treatmentdrugcycledayarrangement.png" width="100%">
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" style="text-align: right;">
          <b-button variant="danger" @click="d_treatmentDrugCycleDayArrange.show = false">Kapat</b-button>
          <b-button variant="success" @click="f_applyDrugCycleDayArrange()">Siklus & Gün düzenleme işlemini uygula</b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import {
  default as WorkSubject
} from '@/components/widgets/WorkSubject';
import {
  default as SqlDatabase
} from '@/components/widgets/SqlDatabase';
import { mapGetters } from 'vuex';
export default {
  name: 'TreatmentDrugCycleDayArrangePage',
  computed: mapGetters({
    // lang: 'lang'
  }),
  components: {
    WorkSubject,
    SqlDatabase,
  },
  props: {},
  data () {
    return {
      d_treatmentDrugCycleDayArrange: {
        'show': false,
        'new': '',
        'offset': 0,
        'reason': []
      },
      d_treatmentDrugCycleArrangeExample: require('@/example_data/treatment_drug_cycle_arrange').example_data,
      d_treatmentList: require('@/example_data/treatment_list').example_data,
      d_patientData: require('@/example_data/patient_data').example_data,
      d_treatmentData: require('@/example_data/treatment_data').example_data,
      d_componentExample: {
        'schema_drugs': [
          { 'code': '<treatment-cycle-day-arrange :p_treatmentDrugCycleArrange="d_treatmentDrugCycleArrangeExample"></treatment-cycle-day-arrange>', 'name': 'Örnek komponent kullanımı' },
        ]
      },
    };
  },
  created: function () {},
  beforeMount () {},
  mounted: function () {},
  methods: {
    f_applyDrugCycleDayArrange: function () {
      console.log('f_applyDrugCycleDayArrange ...');
    },
    f_openTreatmentDrugCycleDayArrange: function () {
      this.d_treatmentDrugCycleDayArrange = {
        'show': true,
        'new': '',
        'offset': 0,
        'reason': []
      };
    }
  },
  watch: {}
}

</script>

<style type="text/css">


</style>

