var data = {
  'cycle_count': 1,
  'drugs': [{
    'parent': 3,
    'DrugId': 1,
    'DrugName': 'Paklitaksel',
    'DrugTypeId': 1,
    'DrugTypeLabel': 'İlaç',
    'cycle_list': [{
      'cycle_no': 1,
      'period': 21,
      'delay': 0,
      'day_list': [{
        'id': 1,
        'PatientId': 1,
        'PatientTreatmentId': 1,
        'PatientTreatmentDrugsId': 1,
        'DrugId': 2,
        'DrugName': 'Deksametazon',
        'DrugApplicationStatusId': 1,
        'DrugApplicationStatusLabel': 'Henüz işlem yapılmadı',
        'DrugTypeId': 2,
        'DrugTypeLabel': 'Ön premedikasyon',
        'UnitId': 1,
        'UnitLabel': 'mg',
        'ApplicationTypeId': 1,
        'ApplicationTypeLabel': 'intravenöz',
        'FluidId': 1,
        'FluidName': '100 cc % 0.9 sodyum klorür',
        'CreatedBy': 1,
        'CreatedByName': 'Ad Soyad',
        'application_start_date': '',
        'application_end_date': '',
        'drug_order': 1,
        'related_drug_id': 2,
        'drug_day': '2022-01-01',
        'dosage': 8,
        'calculated_dosage': 8,
        'planned_dosage': 8,
        'given_dosage': 8,
        'frequency_first': 1,
        'frequency_second': 1,
        'drug_note': '',
        'volume': 100,
        'application_time': 10,
        'drop_minute': 10,
        'infusion_rate': 1000,
        'day': 1,
        'cycle': 1,
      }]
    }, {
      'cycle_no': 2,
      'period': 21,
      'delay': 0,
      'day_list': [{
        'id': 1,
        'PatientId': 1,
        'PatientTreatmentId': 1,
        'PatientTreatmentDrugsId': 1,
        'DrugId': 2,
        'DrugName': 'Deksametazon',
        'DrugApplicationStatusId': 1,
        'DrugApplicationStatusLabel': 'Henüz işlem yapılmadı',
        'DrugTypeId': 2,
        'DrugTypeLabel': 'Ön premedikasyon',
        'UnitId': 1,
        'UnitLabel': 'mg',
        'ApplicationTypeId': 1,
        'ApplicationTypeLabel': 'intravenöz',
        'FluidId': 1,
        'FluidName': '100 cc % 0.9 sodyum klorür',
        'CreatedBy': 1,
        'CreatedByName': 'Ad Soyad',
        'application_start_date': '',
        'application_end_date': '',
        'drug_order': 1,
        'related_drug_id': 2,
        'drug_day': '2022-01-01',
        'dosage': 8,
        'calculated_dosage': 8,
        'planned_dosage': 8,
        'given_dosage': 8,
        'frequency_first': 1,
        'frequency_second': 1,
        'drug_note': '',
        'volume': 100,
        'application_time': 10,
        'drop_minute': 10,
        'infusion_rate': 1000,
        'day': 1,
        'cycle': 2,
      }]
    }, {
      'cycle_no': 3,
      'period': 21,
      'delay': 0,
      'day_list': [{
        'id': 1,
        'PatientId': 1,
        'PatientTreatmentId': 1,
        'PatientTreatmentDrugsId': 1,
        'DrugId': 2,
        'DrugName': 'Deksametazon',
        'DrugApplicationStatusId': 1,
        'DrugApplicationStatusLabel': 'Henüz işlem yapılmadı',
        'DrugTypeId': 2,
        'DrugTypeLabel': 'Ön premedikasyon',
        'UnitId': 1,
        'UnitLabel': 'mg',
        'ApplicationTypeId': 1,
        'ApplicationTypeLabel': 'intravenöz',
        'FluidId': 1,
        'FluidName': '100 cc % 0.9 sodyum klorür',
        'CreatedBy': 1,
        'CreatedByName': 'Ad Soyad',
        'application_start_date': '',
        'application_end_date': '',
        'drug_order': 1,
        'related_drug_id': 2,
        'drug_day': '2022-02-10',
        'dosage': 8,
        'calculated_dosage': 8,
        'planned_dosage': 8,
        'given_dosage': 8,
        'frequency_first': 1,
        'frequency_second': 1,
        'drug_note': '',
        'volume': 100,
        'application_time': 10,
        'drop_minute': 10,
        'infusion_rate': 1000,
        'day': 1,
        'cycle': 2,
      }]
    }],
  }]
};

export { data as example_data };
